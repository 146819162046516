// this file was automatically generated, do not edit
/* eslint-disable */

export interface SetStateExpiryRequestHeaders {}

export interface SetStateExpiryRequestQuery {}

export interface SetStateExpiryRequestParams {
  type: "conversation" | "user" | "bot" | "integration" | "task";
  id: string;
  name: string;
}

export interface SetStateExpiryRequestBody {
  /**
   * Expiry of the [State](#schema_state) in milliseconds. The state will expire if it is idle for the configured value. By default, a state doesn't expire.
   */
  expiry: number | null;
}

export type SetStateExpiryInput = SetStateExpiryRequestBody & SetStateExpiryRequestHeaders & SetStateExpiryRequestQuery & SetStateExpiryRequestParams

export type SetStateExpiryRequest = {
  headers: SetStateExpiryRequestHeaders;
  query: SetStateExpiryRequestQuery;
  params: SetStateExpiryRequestParams;
  body: SetStateExpiryRequestBody;
}

export const parseReq = (input: SetStateExpiryInput): SetStateExpiryRequest & { path: string } => {
  return {
    path: `/v1/chat/states/${encodeURIComponent(input['type'])}/${encodeURIComponent(input['id'])}/${encodeURIComponent(input['name'])}/expiry`,
    headers: {  },
    query: {  },
    params: { 'type': input['type'], 'id': input['id'], 'name': input['name'] },
    body: { 'expiry': input['expiry'] },
  }
}

export interface SetStateExpiryResponse {
  /**
   * The state object represents the current payload. A state is always linked to either a bot, a conversation or a user.
   */
  state: {
    /**
     * Id of the [State](#schema_state)
     */
    id: string;
    /**
     * Creation date of the [State](#schema_state) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [State](#schema_state) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Id of the [Bot](#schema_bot)
     */
    botId: string;
    /**
     * Id of the [Conversation](#schema_conversation)
     */
    conversationId?: string;
    /**
     * Id of the [User](#schema_user)
     */
    userId?: string;
    /**
     * Name of the [State](#schema_state) which is declared inside the bot definition
     */
    name: string;
    /**
     * Type of the [State](#schema_state) represents the resource type (`conversation`, `user`, `bot`, `task` or `integration`) that the state is related to
     */
    type: "conversation" | "user" | "bot" | "task" | "integration";
    /**
     * Payload is the content of the state defined by your bot.
     */
    payload: {
      [k: string]: any;
    };
  };
}

